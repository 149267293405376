import React from 'react';
import './layout.css';
import {PageHeader, PageSection} from './PageSection';
import NavigationSection from './NavigationSection';
import PageControls from './PageControls';
import {
  Container,
  Row,
  Col 
} from 'reactstrap';

// Presentational component to display the main page body.
//  Passes props to SidebarSection and PageSection. 
//   (So might be a candidate for refactoring using a container component to give the children access to the data)
//
function Body(props) {
  return(
    <Container className="instruct-main-section" fluid='true'>
      <Row style={{height: '100%'}}>
        <NavigationSection contentComponent={props.sidebarComponent} />
        <Col md={9} lg={10} className='instruct-content-section' style={{height: '100%'}} role="main">
          <Row style={{ height: '92%' }}>
            <PageSection contentComponent={props.contentComponent}/> 
          </Row>
          <Row style={{ height: '8%' }}>
            <PageControls contentComponent={props.controlsComponent}/>
          </Row>
        </Col>     
      </Row>
    </Container>
  );
}
 
export default Body;
