import React from 'react';
import './layout.css';
import {
  Container,
  Row,
  Col 
} from 'reactstrap';

//Presentational component that displays a sidebar if the page has one.
// TODO: will need to pass in sidebar content in props.
// If no sidebar content, it just holds the space open.
//Bootstrap notes:
//SidebarSection has 2 columns for med+ screen widths (col-md-2). Hide it for smaller screen widths (d-none d-md-block).

function NavigationSection(props) {
    return(
      <Col md="3" lg="2" className='instruct-navigation-section border' role='navigation'>
        {props.contentComponent}
      </Col>
    );
}

export default NavigationSection;
